<template>
  <c-box
    max-width="1270px"
    width="100%"
    margin-inline="auto"
    :margin-top="['0px', '30px']"
    :padding="['16px 0 0 0', '30px 20px']"
    :border-radius="['0px', '16px']"
    :box-shadow="['none', '2px 2px 10px 0px #00000026']"
    :background-color="['#F2F2F2', '#FFF']"
  >
    <c-box
      :display="['none', 'flex']"
      width="100%"
      px="30px"
      py="5px"
      mb="20px"
    > 
      <c-box
        display="flex"
        align-items="center"
        font-size="16px"
        font-weight="400"
        color="primary.400"
        gap="20px"
        cursor="pointer"
        @click="$router.push({ name: 'client.profile.referral' })"
      >
        <inline-svg
          :src="require('@/assets/icon-chevron-left.svg')"
          height="20"
          width="20"
        />
        Kembali
      </c-box>
    </c-box>
    <c-box
      width="100%"
      max-width="1120px"
      margin-inline="auto"
    >
      <c-flex
        :flex-direction="['column', 'row']"
        align-item="center"
        justify-content="space-between"
        :gap="['12px', '16px']"
        :padding-inline="['16px', '0px']"
        margin-bottom="16px"
      >
        <c-box
          :width="['100%', '230px']"
          :order="['2', '1']"
        >
          <CardReferralPoint
            :point="poin"
          />
        </c-box>
        <c-flex
          flex-grow="1"
          :order="['1', '2']"
        >
          <c-flex
            gap="8px"
            width="100%"
            align-items="center"
          >
            <c-box width="100%">
              <c-form-control
                pos="relative"
                w="100%"
              >
                <c-image
                  :src="require('@/assets/ic-search.svg')"
                  alt="icon"
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  :left="['15px', '25px']"
                  :height="['20px', '24px']"
                  z-index="2"
                />
                <c-input
                  id="search"
                  v-model="search"
                  type="text"
                  w="100%"
                  :height="['40px', '62px']"
                  :font-size="['14px', '18px']"
                  placeholder="Search"
                  :border-radius="['30px', '20px']"
                  :padding-left="['45px', '60px']"
                  position="relative"
                  z-index="1"
                  @input="handleSearch"
                />
              </c-form-control>
            </c-box>
            <c-menu
              :auto-select="false"
              :close-on-select="true"
              @blur="true"
            >
              <c-menu-button
                :background-color="['white', '#F2F2F2']"
                color="gray.900"
                :p="['0', '8px 20px']"
                :border-radius="['8px', '1rem']"
                d="flex"
                align-items="center"
                gap="12px"
                :h="['40px', '62px']"
                :w="['40px', 'fit-content']"
              >
                <c-text
                  :display="['none', 'block']"
                  color="#333333"
                  font-size="16px"
                >
                  Filter
                </c-text>
                <c-box
                  :display="['none', 'block']"
                  :width="['20px', '30px']"
                  :height="['20px', '30px']"
                  gap="8px"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-filter.svg')"
                    height="100%"
                    width="100%"
                    fill="#333333"
                  />
                </c-box>
                <c-box
                  :display="['block', 'none']"
                  :width="['20px', '30px']"
                  :height="['20px', '30px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-filter.svg')"
                    height="100%"
                    width="100%"
                    fill="#008C81"
                  />
                </c-box>
                <c-flex
                  v-if="selectedCategory"
                  pos="absolute"
                  top="-2px"
                  right="-2px" 
                  :width="['8px', '10px']"
                  :height="['8px', '10px']"
                  align-items="center"
                  justify-content="center"
                  border-radius="100%"
                  font-size="10px"
                  background-color="danger.400"
                  color="#FFF"
                >
                  <!-- {{ subCategoryParse.length }} -->
                </c-flex>
              </c-menu-button>
              <c-menu-list
                z-index="10"
                :min-width="['150px', '200px']"
                :max-width="['200px', '250px']"
                flex-direction="column" 
                py="12px"
                px="8px"
                placement="left-start"
                border-radius="1rem"
              >
                <c-box
                  v-for="(item, i) in categories"
                  v-show="categories.length > 0"
                  :key="i"
                >
                  <c-menu-item
                    v-chakra="{
                      ':hover': {
                        backgroundColor: 'primary.50'
                      }
                    }"
                    gap="12px"
                    :px="['8px', '16px']"
                    :py="['4px', '8px']"
                    :background-color="selectedCategory && selectedCategory == item?.id ? 'primary.50' : '#FFF'"
                    :color="selectedCategory && selectedCategory == item?.id ? 'primary.400' : '#888'"
                    cursor="pointer"
                    @click="onChangeCategory(item.id)"
                  >
                    <c-text
                      :font-size="['14px', '18px']"
                      :font-weight="['500']"
                    >
                      {{ item?.categoryName }}
                    </c-text>
                  </c-menu-item> 
                  <c-menu-divider
                    v-if="categories.length !== (i+1)"
                    border-color="#FFF"
                    py="4px"
                    m="0"
                  />
                </c-box>
                <c-text
                  v-show="categories.length == 0"
                  px="1rem"
                  font-size="16px"
                  font-weight="500"
                  color="danger.400"
                >
                  Belum ada filter
                </c-text>
              </c-menu-list>
            </c-menu>
          </c-flex>
        </c-flex>
      </c-flex>
      <c-box
        background-color="#FFF"
        :padding-inline="['8px', '0px']"
      >
        <c-tabs
          is-fitted
          is-manual
          :index="activeTabIndex"
          :default-index="activeTabIndex"
          class="custom-tab"
          @change="onChangeTab"
        >
          <c-tab-list>
            <c-tab
              v-for="(label, index) in [
                'Hadiah',
                'Riwayat Poin',
              ]"
              :key="index"
            >
              {{ label }}
            </c-tab>
          </c-tab-list>
        </c-tabs>
        <Gift 
          v-if="activeTabIndex == 0"
          :items="gifts"
          :categories="categories"
          :page="page"
          :total="total"
          :per-page="perPage"
          :is-loading="isLoading"
        />
        <PoinHistory
          v-else-if="activeTabIndex == 1"
          :items="gifts"
          :categories="categories"
          :page="page"
          :total="total"
          :per-page="perPage"
          :is-loading="isLoading"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { reqReferral } from '@/requests/dietela-api/referral'
import { mapActions, mapGetters } from 'vuex'
import CardReferralPoint from '@/components/cards/card-referral-point.vue'
import Gift from '@/views/profile/referral/_widgets/gift.vue'
import PoinHistory from '@/views/profile/referral/_widgets/poin-history.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'Redeem',
  components: {
    CardReferralPoint,
    Gift,
    PoinHistory,
  },
  data() {
    return {
      search: this.$route.query.q ?? '',
      debounceSearch: _.debounce(this.onSearch, 1000),
      categories: [],
      gifts: [],
      histories: [],
      page: 1,
      total: 0,
      perPage: 12,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      poin: 'referral/poin',
      isKol: 'auth/isKol',
    }),
    tab() {
      return this.$route.query.tab
    },
    activeTabMap() {
      return {
        gift: 0,
        history: 1,
      }
    },
    activeTabIndex() {
      return this.activeTabMap[this.tab] ?? 0
    },
    selectedCategory() {
      return this.$route.query?.category ?? ''
      // return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        EventBus.$emit('HEADER_BACK', '/profile/referral')
        this.isLoading = true
        this.page = route.query.page ? parseInt(route.query.page) : 1
        this.perPage = route.query.perpage ?? 12
        if (route.query?.tab == 'history') {
          const res = await reqReferral.poinHistory(this.$store.getters.axios, {
            page: this.page,
            perpage: 100,
            q: route.query?.q,
            filter: route.query?.category,
          })
          this.gifts = res.data?.data?.rows
          this.categories = res.data?.data?.filter.map((it) => {
            return {
              id: it?.value,
              categoryName: it?.item,
            }
          })
          this.total = res.data?.data?.count
        } else {
          const res = await reqReferral.gifts(this.$store.getters.axios, {
            page: this.page,
            perpage: this.perPage,
            q: route.query?.q,
            category: route.query?.category ? JSON.stringify([route.query?.category]) : '',
          })
          this.gifts = res.data?.data?.rows
          this.categories = res.data?.data?.filter
          this.total = res.data?.data?.count
        }
        this.isLoading = false
      },
    },
  },
  mounted() {
    if (this.isKol) {
      this.$toast({
        title: 'Forbidden',
        description: "You don't have permission to access!",
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
      })
      return this.$router.replace({ name: 'client.profile.referral' })
    }
    if (!this.poin) {
      this.getPoin()
    }
  },
  methods: {
    ...mapActions({
      getPoin: 'referral/getPoin',
    }),
    handleSearch() {
      this.debounceSearch()
    },
    onSearch() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          q: this.search,
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onChangeCategory(value) {
      // let category = [value]
      // category = JSON.stringify(category)
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          category: value == this.selectedCategory ? '' : value,
        },
        params: { savePosition: true },
      })
    },
    onChangeTab(index) {
      this.search = ''
      let key = Object.entries(this.activeTabMap).find(([, value]) => value === index)?.[0]
      this.$router
        .push({
          name: this.$route.name,
          query: {
            page: 1,
            tab: key,
          },
          params: { savePosition: true },
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
::v-deep .custom-tab {
  overflow: auto;
  overflow-y: hidden;
}
::v-deep .custom-tab > div > button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #888888;
  white-space: nowrap;
  border-bottom: 2px solid white;
  padding-block: 16px;
}
::v-deep .custom-tab > div > button[aria-selected="true"] {
  color: #008C81;
  border-bottom: 2px solid #008C81;
}
@media (min-width: 768px) {
  ::v-deep .custom-tab > div > button {
    font-size: 18px;
  }
}
</style>